import AWSAmplify, { API as _API, Auth as _Auth, Storage as _Storage, PubSub as _PubSub } from 'aws-amplify'

import config from '../config.json'
import { stage } from '../utils/env'

AWSAmplify.configure(config.amplify)

export async function configureAPIHeaders(options = {}) {
  const configSettings = config.amplify
  const customHeader = async () => ({
    // these http header keys are being set as camelCase here on the client side
    // and are transformed to all lowercase by the time the server picks them up.
    'vms-accountid': options.accountId,
    'vms-userid': options.userId,
    'vms-jwtidtoken': (await _Auth.currentSession()).getIdToken().getJwtToken(),
  })
  configSettings.API.endpoints[0].custom_header = customHeader
  configSettings.API.graphql_headers = customHeader
  AWSAmplify.configure(configSettings)
}

const currentUserInfo = () => {
  _Auth
    .currentUserCredentials()
    .then(async () => {
      const {
        idToken: { jwtToken },
      } = await _Auth.currentSession()
      document.cookie = `VMS_jwtIDToken=${jwtToken}; SameSite=Strict; Max-Age=3600; Path=/`
    })
    .catch(() => {
      // this currently only occurs before the user logs in, resulting in the following console
      // noise: cannot get guest config when mandatory signin enabled. for now, we're
      // just swallowing the error to get a clean console pre-login.
      // TODO: we may want to address this more thoroughly in case the failure occurs in other
      // instances where it's not so harmless....
    })
  return _Auth.currentUserInfo()
}

// we explicitly provide only the bits of Amplify we want to use to prevent inadvertant use
// of Amplify features without considering its implication on dev experience and offline mode
const Auth = {
  signIn: _Auth.signIn.bind(_Auth),
  signUp: _Auth.signUp.bind(_Auth),
  confirmSignUp: _Auth.confirmSignUp.bind(_Auth),
  resendSignUp: _Auth.resendSignUp.bind(_Auth),
  completeNewPassword: _Auth.completeNewPassword.bind(_Auth),
  forgotPassword: _Auth.forgotPassword.bind(_Auth),
  forgotPasswordSubmit: _Auth.forgotPasswordSubmit.bind(_Auth),
  signOut: _Auth.signOut.bind(_Auth),
  currentUserCredentials: _Auth.currentUserCredentials.bind(_Auth),
  currentSession: _Auth.currentSession.bind(_Auth),
  currentUserInfo,
}
const API = {
  get: (apiName, path, init) => _API.get(apiName, `/${stage}${path}`, init),
  post: (apiName, path, init) => _API.post(apiName, `/${stage}${path}`, init),
  endpoint: apiName => _API.endpoint(apiName),
  configureAPIHeaders: options => configureAPIHeaders(options),
  graphql: config => _API.graphql(config),
}
const Storage = {
  put: (key, data, options) => _Storage.put(key, data, options),
  get: (key, options) => _Storage.get(key, options),
}
const PubSub = {
  configure: _PubSub.configure.bind(_PubSub),
  addPluggable: _PubSub.addPluggable.bind(_PubSub),
  subscribe: _PubSub.subscribe.bind(_PubSub),
}

export { API, Auth, Storage, PubSub }
