import Server from '../server/VMSProServerAdapter'
import { getAssumedAuthz, AssumedAuthz } from '../utils/authzUtils'
import { ASSUMED_USER_ID_KEY } from '../utils/appConsts'

interface GetAuthResponse {
  userId: string
  email: string
  assumedAuthz?: AssumedAuthz
}
export async function getAuth(): Promise<GetAuthResponse> {
  return Server.auth.getUser().then(user => {
    const { preferred_username: userId, email } = user?.attributes ?? {}

    const res: GetAuthResponse = {
      userId,
      email,
    }

    const assumedAuthz = getAssumedAuthz()
    if (assumedAuthz) {
      const { type } = assumedAuthz
      switch (type) {
        case 'ASSUME_ROLE': {
          res.assumedAuthz = assumedAuthz
          break
        }
        case 'IMPERSONATE_USER': {
          res.userId = assumedAuthz.assumedUserId
          // TODO: it's somewhat unclear how all of this fits together
          res.assumedAuthz = {
            type,
            assumedUserId: assumedAuthz.assumedUserId,
            actualUserId: userId,
          }
          break
        }
        default: {
          throw new Error(`Assumed authz set with unknown type ${type}`)
        }
      }
    }

    return res
  })
}

export async function signOut() {
  return Server.auth.signOut().then(() => {
    localStorage.removeItem(ASSUMED_USER_ID_KEY)
    window.location.href = '/'
  })
}
