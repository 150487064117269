import { gql } from 'graphql-tag'
import { ROOT_DECISION_NODE_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import Server from '../server/VMSProServerAdapter'

import { CompleteDecisionFragment, DecisionFolderFieldsFragment } from '../graphql/fragments'

const DECISION_NODE_QUERY = gql`
  query DecisionNodeQuery($accountId: ID!, $decisionNodeId: ID!) {
    decisionNode(accountId: $accountId, decisionNodeId: $decisionNodeId) {
      __typename
      ... on Decision {
        ...CompleteDecision
      }
      ... on DecisionFolder {
        ...DecisionFolderFields
      }
    }
  }
  ${CompleteDecisionFragment}
  ${DecisionFolderFieldsFragment}
`

export async function getDecisionNode(accountId, decisionNodeId) {
  return Server.graphql({
    query: DECISION_NODE_QUERY,
    variables: {
      accountId,
      decisionNodeId,
    },
  }).then(async res => {
    const { decisionNode } = res.data

    if (!decisionNode) return

    const ancestors = await Promise.all(
      splitAncestry(decisionNode.ancestry).map(id => {
        return Server.graphql({
          query: DECISION_NODE_QUERY,
          variables: {
            accountId,
            decisionNodeId: id,
          },
        }).then(res => res.data.decisionNode)
      })
    )

    // TODO: until we decide on entityType or __typename, need to map __typename to entityType
    return ancestors.concat(decisionNode).map(n => {
      if (!n.entityType) n.entityType = n.__typename
      return n
    })
  })
}

const DECISION_FOLDER_CHILDREN_QUERY = gql`
  query DecisionNodeChildrenQuery($accountId: ID!, $childAncestry: String!) {
    childDecisionNodes(accountId: $accountId, childAncestry: $childAncestry) {
      __typename
      ... on Decision {
        # we should just load the data necessary for the decision children table here,
        # then load the remainder of the decision data on the decision page
        # (current Redux query management pattern may not support this)
        ...CompleteDecision
      }
      ... on DecisionFolder {
        ...DecisionFolderFields
      }
    }
  }
  ${CompleteDecisionFragment}
  ${DecisionFolderFieldsFragment}
`
export async function getDecisionNodesByAncestry(accountId, childAncestry) {
  return (
    Server.graphql({
      query: DECISION_FOLDER_CHILDREN_QUERY,
      variables: {
        accountId,
        childAncestry,
      },
    })
      // TODO: until we decide on entityType or __typename, need to map __typename to entityType
      .then(res => res.data.childDecisionNodes.map(n => Object.assign(n, { entityType: n.__typename })))
  )
}
