import mime from 'mime'
import qs from 'querystringify'

import { Auth, API, Storage } from './AmplifyProxy'
import md5 from '../utils/md5'
import { API_NAME as apiName } from '../utils/appConsts'

const actionResultListeners = {}

const VMSProServerAdapter = {
  auth: {
    signIn: async (username, password) => {
      if (typeof username === 'string') username = username.toLowerCase().trim()
      if (typeof password === 'string') password = password.trim()
      return Auth.signIn(username.toLowerCase(), password)
    },
    signOut: Auth.signOut.bind(Auth),
    getUser: Auth.currentUserInfo.bind(Auth),
    signUp: Auth.signUp.bind(Auth),
    confirmSignUp: Auth.confirmSignUp.bind(Auth),
    resendSignUp: Auth.resendSignUp.bind(Auth),
    completeNewPassword: async (user, password) => {
      if (typeof password === 'string') password = password.trim()
      return Auth.completeNewPassword(user, password)
    },
    forgotPassword: async username => {
      if (typeof username === 'string') username = username.toLowerCase().trim()
      return Auth.forgotPassword(username.toLowerCase())
    },
    forgotPasswordSubmit: async (username, code, password) => {
      if (typeof username === 'string') username = username.trim()
      if (typeof password === 'string') password = password.trim()
      return Auth.forgotPasswordSubmit(username, code, password)
    },
    configureAPIHeaders: async options => API.configureAPIHeaders(options),
  },

  async graphql(config, additionalHeaders) {
    return API.graphql(config, additionalHeaders)
  },

  async fetchInitialState(userId) {
    return API.get(apiName, `/api/initial-state?type=auth&userId=${userId}`)
  },

  _errorHandler: undefined,
  errorHandler(err) {
    if (typeof this._errorHandler !== 'function') {
      console.error('>> warning: error handler not configured properly')
      console.error(err)
    }
    this._errorHandler(err)
  },

  registerErrorHandler(handler) {
    this._errorHandler = handler
  },

  /**
   * Posts one or more Redux actions to the server.
   */
  postAction(action) {
    const actions = Array.isArray(action) ? action : [action]
    const res = API.post(apiName, '/api/action', { body: actions })
      .then(res => {
        action.forEach(action => {
          const listeners = actionResultListeners[action.meta.seq] || []
          listeners.forEach(l => l(null, res))
        })
        return res
      })
      .catch(err => {
        action.forEach(action => {
          const listeners = actionResultListeners[action.meta.seq] || []
          listeners.forEach(l => l(err))
        })
        this._errorHandler(err)
      })
    return res
  },

  /**
   * Posts a single action to the server.  IMPORTANT NOTE: this method bypasses
   * the usual error-handling method, so it's up to the client to handle errors
   * (promise rejections) from this.  If the action succeeds, the action is handled
   * on the server side, and broadcast to other clients (just as in postAction).
   */
  async tryAction(action) {
    if (Array.isArray(action)) throw new Error('this method only supports single actions')
    return API.post(apiName, '/api/action', { body: [action] })
  },

  stripeQuery(action) {
    const qs = '?action=' + encodeURIComponent(JSON.stringify(action))
    return API.get(apiName, `/api/stripe${qs}`).catch(this._errorHandler)
  },

  resendPassword(email) {
    return API.post(apiName, '/api/resend-password', { body: { email } }).catch(this._errorHandler)
  },

  getItem(id) {
    return API.get(apiName, `/api/get-item/${id}`)
  },

  /**
   * @param {string} ancestry
   * @param {string|void} entityType
   * @param {Array.<string|Array.<string|number>>} [projection]
   */
  getItemsByAncestry(ancestry, entityType, projection) {
    const params = {}
    if (entityType) params.entityType = entityType
    if (projection) params.projection = JSON.stringify(projection)
    // note that underscores and parentheses do not need to be URI-encoded
    const reqUri = `/api/get-items/${encodeURIComponent(ancestry)}${qs.stringify(params, true)}`
    return API.get(apiName, reqUri)
  },

  /**
   * @param {string} ancestryBeginsWith
   * @param {string} entityType
   * @param {Array.<string|Array.<string|number>>} [projection]
   */
  getItemsByAncestryBeginsWith(ancestryBeginsWith, entityType, projection) {
    // ancestry is encoded in getItemsByAncestry
    return this.getItemsByAncestry(`begins_with(${ancestryBeginsWith})`, entityType, projection)
  },

  getRiskContextReport(entityId) {
    return API.get(apiName, `/api/risk-context-report/${entityId}`).catch(this._errorHandler)
  },

  getReport(reportReq) {
    return API.post(apiName, '/api/reports', { body: reportReq })
  },

  authorizeImpersonation(authUserId) {
    return API.get(apiName, `/api/impersonate/${authUserId}`).catch(this._errorHandler)
  },

  getGlobalPolicies() {
    return API.get(apiName, `/api/policies`).catch(this._errorHandler)
  },

  /**
   * @typedef {Object} ProjectFileInfo
   * @property {string} projectId - Project ID
   * @property {string} hash - MD5 hash of file contents
   * @property {string} key - S3 key for project file
   * @property {string} ext - File extension (including period)
   * @property {string} url - URL path to file
   * @property {string} contentType - Detected IANA Media Type
   * @property {File} file - File contents
   */

  /**
   * Get S3 key for entity attachment.  The key is constructed from the account ID, entity ID and the
   * MD5 hash of the file contents, with its extension preserved.
   *
   * @param {string} accountId
   * @param {string} entityId
   * @param {File} file - the file to upload (https://developer.mozilla.org/en-US/docs/Web/API/File)
   * @return {ProjectFileInfo} Identifying information about this project file
   */
  async getEntityAttachmentInfo(accountId, entityId, file) {
    const hash = await md5(file)
    const ext = file.name.trim().replace(/.*(\.\w+)$/, '$1')
    const contentType = mime.getType(ext)
    const key = `${accountId}/${entityId}/${hash}${ext}`
    return {
      accountId,
      entityId,
      hash,
      key,
      ext,
      url: '/entity-resources/' + key,
      contentType,
      file,
    }
  },

  /**
   * Uploads an entity attachment to the server.
   *
   * @param {EntityAttachmentInfo} entityAttachmentInfo - Identifying information about file.
   * @param {function(int, int)} uploadProgress - Callback to report progres; receives loaded and total as args.
   */
  async uploadEntityAttachment(entityAttachmentInfo, uploadProgress) {
    const { key, url, file, contentType } = entityAttachmentInfo
    await Storage.put(key, file, {
      contentType,
      customPrefix: { public: 'entity-resources/' },
      progressCallback: ({ loaded, total }) => {
        uploadProgress({ url, progress: Math.round((loaded / total) * 100), loaded, total })
      },
    })
    return url
  },
}

export default VMSProServerAdapter
