/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { gql } from 'graphql-tag'
import { createSelector } from 'reselect'
import { Link } from 'react-router-dom'
import _keyBy from 'lodash/keyBy'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { DownloadOutlined } from '@ant-design/icons'
import { CellProps, Column } from 'react-table'

import { Cost } from '@vms/vmspro3-core/dist/qty'

import { Table, TableCellRenderer } from '../../common/Table'
import { Page } from '../../common/Page'

import Server from '../../../server/VMSProServerAdapter'
import config from '../../../config.json'
import { selectAccount } from '../../../redux/selectors'
import { useAugmentAction } from '../../../hooks/useAugmentAction'
import { useAppSelector } from '../../../redux'
import { useAccount } from '../../../context'

const productsById = _keyBy(config.instance.products, 'id')

const selectProductIsInternal = createSelector([selectAccount], account => {
  if (!account?.productId) return false
  return productsById[account.productId]?.type === 'Internal'
})

type Invoice = {
  id: string
  createdOn: number
  periodStart: number
  periodEnd: number
  total: Cost
  paid: boolean
  invoiceUrl?: string | null
}

const INVOICES_QUERY = gql`
  query AccountInvoices {
    invoices {
      id
      createdOn
      periodStart
      periodEnd
      total {
        value
        unit
      }
      paid
      invoiceUrl
    }
  }
`

export function InvoicesPage() {
  const { accountId, accountCommonId } = useAccount()
  const productIsInternal = useAppSelector(selectProductIsInternal)

  const [invoices, setInvoices] = useState<Invoice[]>([])

  const augmentAction = useAugmentAction(accountId)
  useEffect(() => {
    if (productIsInternal) return
    Server.graphql({ query: INVOICES_QUERY }).then(response => {
      const res = response as GraphQLResult<{ invoices: Invoice[] }>
      setInvoices(res.data?.invoices ?? [])
    })
  }, [augmentAction, productIsInternal])

  const columns = useMemo<Column<Invoice>[]>(
    () => [
      {
        Header: 'Invoice Date',
        accessor: 'createdOn',
        align: 'right',
        Cell: TableCellRenderer.Timestamp as any,
      },
      {
        Header: 'Billing Period Start',
        accessor: 'periodStart',
        align: 'right',
        Cell: TableCellRenderer.Timestamp as any,
      },
      {
        Header: 'Billing Period End',
        accessor: 'periodEnd',
        align: 'right',
        Cell: TableCellRenderer.Timestamp as any,
      },
      {
        Header: 'Amount',
        accessor: 'total',
        align: 'right',
        Cell: TableCellRenderer.Cost as any,
      },
      {
        Header: 'Paid',
        accessor: 'paid',
        align: 'center',
        Cell: TableCellRenderer.Checkmark as any,
      },
      {
        Header: 'Receipt',
        id: 'invoice-link',
        align: 'center',
        Cell: ({ row }: CellProps<Invoice>) =>
          row.original.invoiceUrl !== null ? (
            <a href={row.original.invoiceUrl}>
              <DownloadOutlined />
            </a>
          ) : null,
      },
    ],
    []
  )

  return (
    <Page title="Invoices">
      {productIsInternal ? (
        <p>This account is subscribed to a free internal product and there are no invoices.</p>
      ) : (
        <Table data={invoices} columns={columns} />
      )}
      <div style={{ marginTop: '32px' }}>
        Looking for <Link to={`/${accountCommonId}/subscription`}>subscription information</Link>?
      </div>
    </Page>
  )
}
