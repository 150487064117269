/**
 * NOTE: to add editable cells, you will need to provide this component to the Table as well as wrap
 * the table in an antd form.
 *
 * Example Usage:
 *  <Form form={form} component={false}>
 *   <Table
 *    components={{ body: { cell: EditableTableCell } }}
 *    columns={columns}
 *    dataRows={dataRpows}
 *    ...
 *   />
 *  </Form>
 *
 * Use antd documentation as reference: https://ant.design/components/table/#components-table-demo-edit-row
 * Also see the implementation in RiskInventoryTable.js & riskInventoryColumns.js
 */
import React from 'react'
import { DurationUnit } from '@vms/vmspro3-core/dist/qty'
import _get from 'lodash.get'
import _pickBy from 'lodash/pickBy'
import { Form, Input, InputNumber } from 'antd'

import { CostInput_Risk, DimensionlessInput, DurationInput, Select } from '../controls'

import RiskCategorySelect from '../components/RiskCategorySelect'
import RiskEnumSelect from '../components/RiskEnumSelect'

/**
 * Provide an editable cell to use inside a table row. Props are provided by Ant Design's onCell callback handler.
 *
 * @param {object} props.children - antd default prop - includes the default text node for the <td> tag
 * @param {string} props.dataIndex - column data index for the value in the record
 * @param {string} [props.defaultTimeUnit=DurationUnit.Months]
 * @param {boolean} props.editing - if true display editable cell
 * @param {object} props.inputProps - Input field properties
 * @param {array<object>} props.inputRules - Form validation rules
 * @param {string} props.inputType - Cell Input Type (i.e: 'TEXT')
 * @param {function} props.onFieldChange - callback handler for field updates
 * @param {string} props.title - column title
 * @param {object} props.record - the editable row record
 */
const EditableCell = ({
  children,
  dataIndex,
  defaultTimeUnit,
  disableOnNull = false,
  editing,
  inputProps,
  inputRules,
  inputType,
  onFieldChange,
  title,
  record,
  ...restProps
}) => {
  // undefined inputType is a non-editable field. Skip the calculations below and return the children
  if (!inputType) return <td {...restProps}>{children}</td>

  const val = _get(record, dataIndex)
  const disabled = disableOnNull && !(typeof val === 'object' && val ? val.value : val)

  let inputNode = null
  switch (inputType) {
    case EditableCell.inputTypes.TEXT: {
      inputNode = <Input onBlur={() => onFieldChange(dataIndex)} {...inputProps} disabled={disabled} />
      break
    }
    case EditableCell.inputTypes.NUMBER: {
      inputNode = <InputNumber onBlur={() => onFieldChange(dataIndex)} {...inputProps} disabled={disabled} />
      break
    }
    case EditableCell.inputTypes.DIMENSIONLESS: {
      inputNode = (
        <DimensionlessInput onBlur={() => onFieldChange(dataIndex)} {...inputProps} disabled={disabled} />
      )
      break
    }
    case EditableCell.inputTypes.CURRENCY: {
      inputNode = <CostInput_Risk onBlur={() => onFieldChange(dataIndex)} {...inputProps} disabled={disabled} />
      break
    }
    case EditableCell.inputTypes.DURATION: {
      inputNode = <DurationInput onBlur={() => onFieldChange(dataIndex)} {...inputProps} disabled={disabled} />
      break
    }
    case EditableCell.inputTypes.SELECT: {
      const { label: labelIdx = 'long', selectOptions, allowClear = false } = inputProps
      const selectProps = _pickBy(inputProps, (v, k) => !['label', 'selectOptions'].includes(k))
      inputNode = (
        <Select
          onChange={() => onFieldChange(dataIndex)}
          {...selectProps}
          disabled={disabled}
          allowClear={allowClear}
        >
          {selectOptions?.map(({ key, label, value }) => (
            <Select.Option key={key || value}>{label[labelIdx]}</Select.Option>
          ))}
        </Select>
      )
      break
    }
    case EditableCell.inputTypes.RISK_ENUM_SELECT: {
      const { selectOptions, allowClear = false } = inputProps
      inputNode = (
        <RiskEnumSelect
          onChange={() => onFieldChange(dataIndex)}
          enumValues={selectOptions}
          disabled={disabled}
          allowClear={allowClear}
        />
      )
      break
    }
    case EditableCell.inputTypes.RISK_CATEGORY_SELECT: {
      // TODO: for some reason, this is resulting in a "Cannot update during an existing state transition"
      // error on the console, but it works correctly...
      inputNode = (
        <RiskCategorySelect
          ref={inputNode}
          category={val}
          categoryValues={inputProps.categoryValues}
          onChange={() => onFieldChange(dataIndex)}
          readOnly={disabled}
        />
      )
      break
    }
    default: {
      throw new Error(`Invalid Input Type found in EditableTableCell: ${inputType}`)
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={inputRules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

EditableCell.defaultProps = {
  defaultTimeUnit: DurationUnit.Months,
}

EditableCell.inputTypes = {
  CURRENCY: 'CURRENCY',
  DIMENSIONLESS: 'DIMENSIONLESS',
  DURATION: 'DURATION',
  NUMBER: 'NUMBER',
  RISK_CATEGORY_SELECT: 'RISK_CATEGORY_SELECT',
  RISK_ENUM_SELECT: 'RISK_ENUM_SELECT',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
}

export default EditableCell
