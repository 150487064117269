import { ReactNode, useCallback, useEffect, useReducer } from 'react'
import { captureException, captureMessage, configureScope } from '@sentry/browser'
import { Modal, Spin } from 'antd'

import { ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'

import { AuthenticatedRoutes, UnauthenticatedRoutes } from './Routes'
import { AuthProvider, UserAccountsProvider } from '../../context'

import Server from '../../server/VMSProServerAdapter'
import pubSub from '../../services/pubSubService'
import { useAppDispatch, useAppSelector } from '../../redux'
import { fetchAuth, fetchPolicies, fetchCurrentUser } from '../../redux/actions'
import { AssumedAuthorizationHeader } from './AssumedAuthorizationHeader'
import { RiskModalContext, visibleModalsInitialState, visibleModalsReducer } from '../risk/RiskModalContext'

import _keyBy from 'lodash/keyBy'
import { modals } from '../risk/modals'

interface CustomErrorMessage {
  header: string
  body: string
  details: string
}

function useRegisterErrorHandler(): void {
  const dispatch = useAppDispatch()
  useEffect(() => {
    /**
     * Calls sentry with the error information and then dispatches the Sentry Id
     * and error to the Error Modal for user notification.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleError = (error: any, customMessage?: CustomErrorMessage) => {
      // Session Expired error type will be common if anyone leaves a session open overnight.
      // Sentry is capturing the error with relevant data and we are displaying a modal to the user.
      // No need to add a console error for this as well.
      let sentryId = undefined
      // 'AMQJS0007E Socket Error:undefined.'
      // is an MQTT Socket error triggered when the pub/sub socket is destroyed.
      if (!(error.errorMessage && error.errorMessage.includes('AMQJS0007E'))) {
        if (error.response?.data.sentToSentry) {
          // error already reported to Sentry from server
          sentryId = error.response.data.sentryEventId
        } else {
          sentryId = error
            ? captureException(error)
            : captureMessage('VMSProServer invoked error handler without an error object')
        }
      }

      const content = customMessage ? (
        <>
          <h3>{customMessage.header}</h3>
          <p>{customMessage.body}</p>
          <p>{customMessage.details}</p>
        </>
      ) : error.response?.status === 403 ? (
        <p>{error.response.data.message}</p>
      ) : (
        <p>We&apos;re sorry, we&apos;ve encountered an error. The development team has been notified.</p>
      )

      Modal.confirm({
        cancelText: 'Go Home',
        onCancel: () => (window.location.href = '/'),
        okText: 'Reload Page',
        onOk: () => window.location.reload(),
        // from AWS Amplify documentation:
        //  > Under the hood the API category utilizes Axios to execute the
        //  > HTTP requests. API status code response > 299 are thrown as an
        //  > exception. If you need to handle errors managed by your API,
        //  > work with the error.response object."
        // see: https://aws-amplify.github.io/docs/js/api#using-the-api-client
        title: error.response?.status === 403 ? 'Not Authorized' : 'System Error',
        content: (
          <>
            {content}
            <p style={{ fontSize: 'smaller' }}>
              Error receipt ID: <span style={{ fontFamily: 'monospace' }}>{sentryId}</span>
            </p>
          </>
        ),
      })
    }

    Server.registerErrorHandler(handleError)
    pubSub.registerErrorHandler(handleError)
  }, [dispatch])
}

function useAuthentication() {
  const dispatch = useAppDispatch()
  const authLoadingStatus = useAppSelector(state => state.auth.loadingStatus)
  useEffect(() => {
    if (authLoadingStatus === 'NotLoaded') {
      Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID })
      dispatch(fetchAuth())
    }
  }, [dispatch, authLoadingStatus])

  const authUserId = useAppSelector(state => state.auth.userId)
  const authUserEmail = useAppSelector(state => state.auth.email)

  const policiesLoadingStatus = useAppSelector(state => state.policies.loadingStatus)
  useEffect(() => {
    if (authUserId && policiesLoadingStatus === 'NotLoaded') {
      // TODO: resolve header configuration fussiness
      Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID, userId: authUserId })
      dispatch(fetchPolicies())
    }
  }, [dispatch, authLoadingStatus, authUserId, policiesLoadingStatus])

  const currentUserLoadingStatus = useAppSelector(state => state.user.currentUserLoadingStatus)
  useEffect(() => {
    if (authUserId && currentUserLoadingStatus === 'NotLoaded') {
      // TODO: resolve header configuration fussiness
      Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID, userId: authUserId })
      dispatch(fetchCurrentUser(authUserId))
    }
  }, [dispatch, authUserId, currentUserLoadingStatus])

  useEffect(() => {
    configureScope(scope =>
      scope.setUser({
        id: authUserId,
        email: authUserEmail,
        sessionStarted: Date.now(),
      })
    )
  }, [authUserId, authUserEmail])

  const authUser = useAppSelector(state => state.user.currentUser)

  const authLoading = authLoadingStatus !== 'Loaded'
  const authUserLoading = !!authUserId && currentUserLoadingStatus !== 'Loaded'
  const policiesLoading = !!authUserId && policiesLoadingStatus !== 'Loaded'
  const loading = authLoading || authUserLoading || policiesLoading

  return { authUser, authUserId, loading }
}

export function App() {
  useRegisterErrorHandler()
  const { authUser, authUserId, loading } = useAuthentication()

  const assumedAuthz = useAppSelector(state => state.auth.assumedAuthz)

  if (loading) {
    return <Spin />
  }

  if (authUser && authUserId) {
    return (
      <AuthProvider authUser={authUser} authUserId={authUserId} assumedAuthz={assumedAuthz}>
        <UserAccountsProvider authUserId={authUserId}>
          {assumedAuthz && <AssumedAuthorizationHeader />}
          <ModalsProvider>
            <AuthenticatedRoutes />
          </ModalsProvider>
        </UserAccountsProvider>
      </AuthProvider>
    )
  }

  return <UnauthenticatedRoutes />
}

const modalsById = _keyBy(modals, 'id')

type ModalsProviderProps = {
  children: ReactNode
}
/**
 * This used to live in src/components/risk/riskRoutes.tsx, but for
 * #2455, ResourceAuthorizationEditor is being reused for decision
 * folder context authz, which means it needs some risk modals.
 */
function ModalsProvider(props: ModalsProviderProps) {
  const [state, dispatch] = useReducer(visibleModalsReducer, visibleModalsInitialState)

  const showModal = useCallback((modalId: string, modalData: Record<string, unknown> = {}) => {
    dispatch({
      type: 'ShowModal',
      modalId,
      modalData,
    })
  }, [])

  const hideModal = useCallback(
    (modalId: string) =>
      dispatch({
        type: 'HideModal',
        modalId,
      }),
    []
  )

  return (
    <RiskModalContext.Provider value={{ showModal, hideModal, byId: state.byId }}>
      {state.stack.map(modalId => {
        const Modal = modalsById[modalId]
        return <Modal key={modalId} />
      })}
      {props.children}
    </RiskModalContext.Provider>
  )
}
