import { gql } from 'graphql-tag'
import { GraphQLResult } from '@aws-amplify/api-graphql'

import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { Invitation } from '@vms/vmspro3-core/dist/types'

import Server from '../server/VMSProServerAdapter'

export async function getPendingInvitationsForUser(email: string) {
  return Server.getItemsByAncestryBeginsWith(email, EntityType.USER_INVITATION).then(
    ({ entities }: { entities: Invitation[] }) => entities.filter(inv => !inv.accepted)
  )
}

const INVITATIONS_FOR_ACCOUNT_QUERY = gql`
  query InvitationsForAccount($accountId: ID!) {
    invitationsForAccount(accountId: $accountId) {
      fullName
      id
      email
      accepted
      created
      inviteToAccountId
      invitedByName
    }
  }
`
export async function getPendingInvitationsForAccount(accountId: string) {
  return Server.graphql({
    query: INVITATIONS_FOR_ACCOUNT_QUERY,
    variables: { accountId },
  }).then(response => {
    // TODO: pass generic params through server adapter to amplify proxy methods
    const res = response as GraphQLResult<{ invitationsForAccount: Invitation[] }>
    return res.data?.invitationsForAccount.filter(inv => !inv.accepted) ?? []
  })
}
