import { ROOT_DECISION_NODE_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { DecisionFolder } from '@vms/vmspro3-core/src/types'
import { TreeSelect } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { gql } from 'graphql-tag'
import { useMemo, useState } from 'react'
import { useAccount } from '../../context'
import Server from '../../server/VMSProServerAdapter'

const DECISION_ANCESTRY_FOLDERS_WITH_CHILDREN = gql`
  query AllFolders($accountId: ID!) {
    allFolders(accountId: $accountId) {
      id
      ancestry
      name
    }
  }
`

function foldersToTreeData(folders: DecisionFolder[], rootFolderName = 'All Decisions') {
  const treeData = [
    {
      id: ROOT_DECISION_NODE_ID,
      pId: undefined,
      value: ROOT_DECISION_NODE_ID,
      title: rootFolderName,
      isLeaf: true, // default to true; we'll correct in the next step
      isExpanded: true,
    },
    ...folders.map(folder => {
      const pId = splitAncestry(folder.ancestry).pop()
      return {
        id: folder.id,
        pId,
        value: folder.id,
        title: folder.name,
        isLeaf: true, // default to true; we'll correct in the next step
      }
    }),
  ]
  const parentIds = new Set(treeData.map(d => d.pId))
  return treeData.map(d => {
    if (parentIds.has(d.id)) d.isLeaf = false
    return d
  })
}

interface DecisionFolderTreeSelectProps {
  initialSelectedFolderPath: string
  onSelect: (folderId: string) => void
}
export function DecisionFolderTreeSelect(props: DecisionFolderTreeSelectProps) {
  const {
    accountId,
    account: { name: accountName },
  } = useAccount()

  const [folders, setFolders] = useState<Omit<DefaultOptionType, 'label'>[]>([])
  const loading = folders.length === 0

  const [initialSelectedFolderId, expandedKeys] = useMemo(() => {
    const [folderId, ...ancestors] = splitAncestry(props.initialSelectedFolderPath).reverse()
    return [folderId, ancestors.concat(ROOT_DECISION_NODE_ID)]
  }, [props.initialSelectedFolderPath])

  useMemo(() => {
    Server.graphql({
      query: DECISION_ANCESTRY_FOLDERS_WITH_CHILDREN,
      variables: { accountId, folderId: initialSelectedFolderId },
    }).then(resp => {
      const folders = foldersToTreeData(resp.data.allFolders, accountName)
      setTimeout(() => setFolders(folders), 1000)
    })
  }, [accountId, accountName, initialSelectedFolderId])

  return (
    <TreeSelect
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={loading ? 'Loading folders, please wait...' : 'Select destination folder'}
      treeDataSimpleMode
      treeData={folders}
      loading={loading}
      disabled={loading}
      treeDefaultExpandedKeys={expandedKeys}
      value={loading ? undefined : initialSelectedFolderId}
      onSelect={props.onSelect}
    />
  )
}
