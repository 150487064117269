import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { DurationUnit } from '@vms/vmspro3-core/dist/qty'
import moment from 'moment'
import _groupBy from 'lodash/groupBy'
import { Button, Spin } from 'antd'

import { Table } from '../controls'

import Server from '../../../server/VMSProServerAdapter'
import { selectDateTimeFormat } from '../../../redux/selectors'
import { useFetchUsers } from '../../../redux/hooks'
import { formatCostObject } from '../utils/formatCostObject'
import { formatDuration } from '../../../utils/formatUtils'
import { useUsers } from '../hooks/hooks'
import { useAccount } from '../../../context'

const { EntityType, riskImpactTypesByKey } = systemConsts

const RiskPropertyHistory = ({ effectiveRiskContext, projectId, riskId }) => {
  useFetchUsers()
  const { accountId } = useAccount()
  const dateTimeFormat = useSelector(selectDateTimeFormat)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const [riskHistory, setRiskHistory] = useState([])
  const [loadingHistory, setLoadingHistory] = useState(false)
  useEffect(() => {
    setLoadingHistory(true)
    Server.getItemsByAncestryBeginsWith(`/${riskId}`, EntityType.PROPERTY_HISTORY).then(({ entities }) => {
      setRiskHistory(entities)
      setLoadingHistory(false)
    })
  }, [accountId, projectId, riskId])

  const [usersById, loadingUsers] = useUsers()

  if (loadingUsers) return <Spin />

  // group history items by property key and create outer table rows
  const rows = Object.entries(_groupBy(riskHistory, 'key')).map(([key, history]) => {
    // sort nested table rows
    history = history
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(h => ({
        ...h,
        username: usersById[h.userId]?.fullName ?? '<ERROR>',
      }))

    return {
      key,
      history,
      created: history[0].timestamp,
      lastUpdated: history[history.length - 1].timestamp,
      nChanges: history.length,
    }
  })

  function renderObject(v) {
    switch (v?.$type) {
      case 'currency':
        return formatCostObject(v)
      case 'duration':
        return formatDuration(v, DurationUnit.Months)
      // eslint-disable-next-line react/no-danger
      case 'html':
        return <div dangerouslySetInnerHTML={{ __html: v.value }} />
      default:
        return JSON.stringify(v)
    }
  }

  const configEnumDfns = Object.values(effectiveRiskContext.types).reduce(
    (enumDfns, dfn) => {
      enumDfns.keys.push(dfn.key)
      enumDfns.byKey[dfn.key] = {}
      dfn.values.forEach(v => (enumDfns.byKey[dfn.key][v.value] = v))
      return enumDfns
    },
    { keys: [], byKey: {} }
  )

  function renderValue(key, v) {
    if (key === 'type' || /impact\.\w+\.type$/.test(key)) {
      return riskImpactTypesByKey[v]?.label ?? v
    }
    if (['name', 'num'].includes(key) || key.includes('qual')) return v
    if (key.includes('impact.perf.quant') || key.includes('prob.quant')) {
      return Number.isFinite(v) ? (v * 100).toFixed(2) + '%' : String(v)
    }
    if (key.includes('severity')) {
      return Number.isFinite(v) ? v.toFixed(2) : String(v)
    }
    if (configEnumDfns.keys.includes(key)) {
      const { label } = configEnumDfns.byKey[key][v] ?? {}
      return label?.long ?? label?.short ?? v
    }
    return <span>{v}</span>
  }

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      sortType: 'string',
    },
    {
      title: 'Time Created',
      dataIndex: 'created',
      sortType: 'number',
      align: 'right',
      render: t => moment(t).format(dateTimeFormat),
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      sortType: 'number',
      align: 'right',
      render: t => moment(t).format(dateTimeFormat),
    },
    {
      title: 'Number of Changes',
      dataIndex: 'nChanges',
      sortType: 'number',
      align: 'right',
    },
  ]

  const expandedRowRender = ({ history }) => {
    const expandedColumns = [
      {
        title: 'Date of Change',
        dataIndex: 'timestamp',
        sortType: 'number',
        render: t => moment(t).format(dateTimeFormat),
      },
      {
        title: 'User',
        dataIndex: 'username',
        sortType: 'string',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        align: 'right',
        render: (v, { key }) => (v && typeof v === 'object' ? renderObject(v) : renderValue(key, v)),
      },
    ]

    return <Table rowKey="id" columns={expandedColumns} dataSource={history} />
  }

  return (
    <div style={style.container}>
      <div style={style.controls}>
        <Button onClick={() => setExpandedRowKeys([])}>Collapse All</Button>
      </div>
      <Table
        rowKey="key"
        columns={columns}
        dataSource={rows}
        loading={loadingHistory}
        expandedRowRender={expandedRowRender}
        expandedRowKeys={expandedRowKeys}
        onExpandedRowsChange={setExpandedRowKeys}
      />
    </div>
  )
}

const style = {
  container: {
    padding: '0 24px 24px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 12px',
    width: '100%',
  },
}

export default RiskPropertyHistory
