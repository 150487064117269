import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, Statistic, Spin, Row, Col, Tabs, Dropdown, Menu, Space } from 'antd'

import { Color, EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { Cost, CostUnit } from '@vms/vmspro3-core/dist/qty'
import { actions } from '@vms/vmspro3-core/dist'

import AggregateRiskDataDisplay from './AggregateRiskDataDisplay'
import RiskEntityAttachmentManager from './RiskEntityAttachmentManager'
import RiskEntityCreateModal from '../modals/RiskEntityCreateModal'
import RiskPortfolioEntityTable from '../tables/RiskPortfolioEntityTable'
import RiskPortfolioNodeHeader from './RiskPortfolioNodeHeader'
import { ButtonLink } from '../controls'

import Server from '../../../server/VMSProServerAdapter'
import useAuthz from '../../../hooks/useAuthz'
import useEnforceProjectsQuota from '../../../hooks/useEnforceProjectsQuota'
import useQuerystringTabKey from '../hooks/useQuerystringTabKey'
import { useRiskEntityChildren } from '../hooks/useRiskEntity'
import { useShowModal } from '../RiskModalContext'
import { testId } from '../../../test-automation'

const getAllowableContainerTypes = childEntityType => {
  switch (childEntityType) {
    case EntityType.PORTFOLIO:
      return [EntityType.PORTFOLIO]
    case EntityType.PROGRAM:
      return [EntityType.PORTFOLIO]
    case EntityType.PROJECT:
      return [EntityType.PORTFOLIO, EntityType.PROGRAM]
    default:
      return []
  }
}

function RiskPortfolioNode({ entityId }) {
  const authz = useAuthz()

  const { ancestry: entityAncestry, entityType } = useSelector(state => state.riskEntities.byId[entityId])
  const childAncestry = joinAncestry(entityAncestry, entityId)
  const children = useRiskEntityChildren(entityId, { loadChildren: true })

  // TODO: this is merely a demo implementation of aggregate data; we'll need to do some more
  // thinking about when this data is fetched/refreshed
  const [projectStats, setProjectStats] = useState({})
  // load aggregate project costs
  useEffect(() => {
    Server.getItemsByAncestryBeginsWith(childAncestry, EntityType.PROJECT, ['cost']).then(
      ({ entities: projects }) => {
        setProjectStats({
          count: projects.length,
          // TODO: where to get "master" default cost unit?  root portfolio?
          totalCost: Cost.sum(
            projects.map(p => p.cost),
            CostUnit.USD
          ),
        })
      }
    )
  }, [childAncestry])

  const { tabKey, onTabChange } = useQuerystringTabKey('contents')

  const canEditConfig = authz(
    actions.riskContext.update(
      {},
      {
        entityId,
        entityType,
        ancestry: entityAncestry,
      }
    )
  )

  const showModal = useShowModal()
  const [enforceProjectsQuota] = useEnforceProjectsQuota()
  const onChildCreateClick = useCallback(
    ({ key: createEntityType }) => {
      const dispatchShowModal = () =>
        showModal(RiskEntityCreateModal.id, {
          ancestry: childAncestry,
          entityType: createEntityType,
        })

      if (createEntityType === EntityType.PROJECT) {
        const showProjectCreateModal = enforceProjectsQuota(dispatchShowModal)
        showProjectCreateModal()
      } else {
        dispatchShowModal()
      }
    },
    [childAncestry, enforceProjectsQuota, showModal]
  )

  const menuItems = useMemo(
    () =>
      [
        {
          childType: EntityType.PORTFOLIO,
          childTypeLabel: 'Portfolio',
          action: actions.riskPortfolio.create({
            name: 'any',
            description: '',
            ancestry: childAncestry,
          }),
        },
        {
          childType: EntityType.PROGRAM,
          childTypeLabel: 'Program',
          action: actions.riskProgram.create({
            name: 'any',
            description: '',
            ancestry: childAncestry,
          }),
        },
        {
          childType: EntityType.PROJECT,
          childTypeLabel: 'Project',
          action: actions.riskProject.create({
            name: 'any',
            description: '',
            cost: {},
            time: {},
            riskContext: {},
            ancestry: childAncestry,
          }),
        },
      ]
        .filter(({ childType, action }) => {
          const containerTypes = getAllowableContainerTypes(childType)
          return containerTypes.includes(entityType) && authz(action)
        })
        .map(({ childType, childTypeLabel }) => (
          <Menu.Item {...testId(childTypeLabel)} key={childType}>
            {childTypeLabel}
          </Menu.Item>
        )),
    [authz, entityType, childAncestry]
  )

  return (
    <>
      <RiskPortfolioNodeHeader
        ancestry={entityAncestry}
        entityId={entityId}
        extra={
          <Row gutter={24}>
            <Col>
              <Statistic title="Total Projects" value={projectStats.count} precision={0} />
            </Col>
            <Col>
              <Statistic title="Total Cost" value={projectStats.totalCost?.value} precision={0} />
            </Col>
          </Row>
        }
      />
      <div style={style.container}>
        <Tabs
          activeKey={tabKey}
          onChange={onTabChange}
          tabBarExtraContent={
            <Space size="middle">
              {canEditConfig && (
                <ButtonLink to="config" icon={<SettingOutlined />}>
                  Configuration
                </ButtonLink>
              )}
              {menuItems.length > 0 && (
                <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu onClick={onChildCreateClick} theme="dark">
                      {menuItems}
                    </Menu>
                  }
                >
                  <Button type="primary" icon={<PlusOutlined />}>
                    Create
                  </Button>
                </Dropdown>
              )}
            </Space>
          }
        >
          <Tabs.TabPane tab="Contents" key="contents">
            {Array.isArray(children) ? (
              <>
                <RiskPortfolioEntityTable rows={children} />
                <AggregateRiskDataDisplay riskAncestry={childAncestry} parentId={entityId} />
              </>
            ) : (
              <Spin />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attachments" key="attachments">
            <RiskEntityAttachmentManager entityId={entityId} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}

const style = {
  container: {
    backgroundColor: Color.WHITE,
    margin: '24px 30px',
    padding: '12px 24px',
  },
}

export default RiskPortfolioNode
